import Exchange from "components/exchange/exchange";
import Fees from "components/fees/fees";
import ReferralOverview from "components/referral-overview/referral-overview";
import Footer from "components/footer/footer";
import Header from "components/header/header";
import Lending from "components/lending/lending";
import Margin from "components/margin/margin";
import Message from "components/message/message";
import PositionsAndHistory from "components/positions-and-history/positions-and-history";
import Sidebar from "components/sidebar/sidebar";
import TopHeader from "components/topHeader/TopHeader";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useMediaQuery } from "react-responsive";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { tryReconnect } from "utils/conncetioncode";
import pathNames from "utils/path-names";
import ScrollToTop from "utils/ScrollToTop";
import styles from "./App.module.css";
import Load from 'components/load-event/Load';
import projectConfig from '_customization/project-config'

function App() {
  //TransformStream();
  const { configs } = projectConfig
  const dispatch = useDispatch();
  const isSmallScreen = useMediaQuery({ query: '(max-width: 450px)' })
  const [modal, setModal] = useState(!(localStorage.getItem("ShowDisclaimer") === 'false'));

  useEffect(() => {
    const interval = setInterval(function () {
      dispatch({ type: "triggerPeriodicUpdate" });
    }, 10000);
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    tryReconnect(0).then((ret) => {
      if (ret?.error === false && ret?.acounts) {
        dispatch({
          type: "web3Change",
          web3: ret.web3,
          userAddress: ret.acounts,
        });
      }
    });
  }, []);

  return (
    <Router>
      <ScrollToTop />
      <div className={styles.fullContainer}>
        {configs.topHeader && <TopHeader/>}
        <Header />
        <div className={styles.row}>
          {!isSmallScreen && <Sidebar />}
          <Message />
          {modal && <Load modal={setModal} />}
          <div className={styles.content}>
            <Routes>
              <Route path={pathNames.default} element={<Margin />} />
              <Route path={pathNames.margin} element={<Margin />} />
              <Route path={pathNames.exchange} element={<Exchange />} />
              {projectConfig.configs.tokenHold && <Route path={pathNames.fees} element={<Fees/>}/>}
              <Route path={pathNames.referral_overview} element={<ReferralOverview />} />
              <Route path={pathNames.lending} element={<Lending />} />
              <Route
                path={pathNames.positions_and_history}
                element={<PositionsAndHistory />}
              />
            </Routes>
            <Footer />
          </div>
        </div>
      </div>
    </Router>
  );
}

export default App;
