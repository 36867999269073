import classNames from "classnames";
import { useState } from "react";
import styles from "./positions.module.css";
import { useTranslation } from 'react-i18next'

function Positions(props) {
  return (
    <div>
      {props.positions?.map((position, ix) => {
        return Item(ix, position, props.close);
      })}
    </div>
  );
}
export default Positions;

function Item(key, position, close) {
  const [disable, setDisable] = useState(false);
  const { t } = useTranslation();
  const isClosed = "closingPrice" in position;
  const hideSLTP =
    Number(position.stopLossPercent) === 0 &&
    Number(position.takeProfitPercent) === 0;
  return (
    <div key={key} className={styles.row}>
      <div
        className={classNames(
          styles.col1
          //position.position === "Short" ? styles.short : styles.long
        )}
      >
        {position.position}
      </div>
      <div className={styles.col2}>
        <div>{t("openPosition.Pnl_text")}</div>
        <div className={Number(position.pnl) >= 0 ? styles.green : styles.red}>
          {Number(position.pnl)}%
        </div>
      </div>
      <div className={styles.col3}>
        <div>{t("openPosition.Profit_text")}</div>
        <div
          className={Number(position.profit) >= 0 ? styles.green : styles.red}
        >
          ${Number(position.profit)}
        </div>
      </div>
      <div className={styles.col4}>
        <div>{t("openPosition.TP_text")}</div>
        <div>
          {!hideSLTP && (
            <span className={classNames(styles.green)}>
              {Number(position.takeProfitPercent) / 100}%&nbsp;
            </span>
          )}
          -
          {!hideSLTP && (
            <span className={classNames(styles.red)}>
              &nbsp;{Number(position.stopLossPercent) / 100}%
            </span>
          )}
        </div>
      </div>
      <div className={styles.col5}>
        <div className={styles.col5_title}>{t("openPosition.TotalSize_text")}</div>
        <div className={styles.col5_value}>
          {position.totalSize}&nbsp;
          {position.position === "Short" ? position.asset : "$"}
        </div>
      </div>
      <div className={styles.col6}>
        <div className={styles.col5_title}>{t("openPosition.Commitment_text")}</div>
        <div className={styles.col5_value}>
          {position.commitment}$
        </div>
      </div>
      <div className={styles.col7}>
        <div className={styles.col5_title}>{t("openPosition.EntryPrice_text")}</div>
        <div className={styles.col5_value}>
          {position.entryPrice}$
        </div>
      </div>
      {!isClosed && (
        <div className={styles.col8}>
          <div className={styles.col5_title}>{t("openPosition.CurrentPrice_text")}</div>
          <div className={classNames(styles.col5_value)}>
            <span className={classNames(styles.tooltip)}>
              {position.curPrice}$
              <span className={styles.tooltiptext}>{t("openPosition.PriceImpactIncluded_text")}</span>
            </span>
          </div>
        </div>
      )}
      <div className={styles.col9}>
        <div className={styles.col5_title}>{t("openPosition.Asset_text")}</div>
        <div className={styles.col5_value}>{position.asset}</div>
      </div>
      <div className={styles.col10}>
        <div className={styles.col5_title}>{t("openPosition.Leveraged_text")}</div>
        <div className={styles.col5_value}>{position.leverage}x</div>
      </div>
      <div className={styles.col11}>
        <div className={styles.col5_title}>
          {isClosed ? t("openPosition.ClosingPrice_text") : t("openPosition.LiquidationPrice_text")}
        </div>
        <div className={styles.col5_value}>
          {isClosed ? position.closingPrice : position.liqPrice}
          $
        </div>
      </div>
      {!isClosed && (
        <button
          className={classNames(styles.closeBtn)}
          disabled={disable}
          onClick={() => {
            setDisable(true);
            close(position, () => setDisable(false));
          }}
        >
            {t("Positions&History.Close_text")}
        </button>
      )}
    </div>
  );
}
