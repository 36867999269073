import { ReactComponent as DownIcon } from 'assets/arrow_circle_indicator_down.svg';
import { ReactComponent as UpIcon } from 'assets/arrow_circle_indicator_up.svg';
import classNames from 'classnames';
import TokenIcon from 'components/tokenIcon/tokenIcon';
import usePool from 'hooks/usePool';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import styles from './lending.module.css';
import loading from '_customization/assets/loading.gif';
import { useTranslation } from 'react-i18next';
import projectConfig from '_customization/project-config';
import PageName from 'components/_shared/page-name/PageName';

function Lending() {
  const { t } = useTranslation();
  const isSmallScreen = useMediaQuery({ query: '(max-width: 450px)' });
  
  const { getAllPools, getPoolData } = usePool();
  const userAddress = useSelector((state) => state.userAddress);
  const [pools, setPools] = useState([]);
  const [totalSupply, setTotalSupply] = useState(0);
  const [totalLoaned, setTotalLoaned] = useState(0);
  const [inprogress, setInprogress] = useState(false);
  
  useEffect(() => {
    async function fn() {
      setInprogress(true);
      const allPools = getAllPools().filter((pool) => pool.title !== 'NIOB');
      const list = [];
      let totalSupply = 0;
      let totalLoaned = 0;
      for (let ix = 0; ix < allPools.length; ix++) {
        const res = await getPoolData(allPools[ix].address);
        //console.log(res);
        if (res) {
          list.push(res);
          totalSupply += Number(res.totalSupplyUSD);
          totalLoaned += Number(res.loanUSD);
        }
      }
      setTotalLoaned(totalLoaned);
      setTotalSupply(totalSupply);
      setPools(list);
      setInprogress(false);
    }
    
    fn();
  }, [userAddress]);
  
  function updatePool(poolAddress) {
    getPoolData(poolAddress).then((res) => {
      if (res) {
        setPools(
          pools.map((pool) => (pool.poolAddress === poolAddress ? res : pool))
        );
      }
    });
  }
  
  return (
    <div className={styles.container}>
      <PageName
        earning
        title={t('lending.earning_text')}
        text={`${t('lending.passivepara_object.text1')} ${projectConfig.tokenName} ${t('lending.passivepara_object.text2')}`}
      />
      {/*<div className={styles.bg}>{t("lending.earning_text")}</div>
      <div className={styles.sec}>
        <div className={styles.rs1}>{t("lending.earning_text")}</div>
        <div className={styles.rs2}>
          {t("lending.passivepara_object.text1")}
          {" " + projectConfig.tokenName + " "}
          {t("lending.passivepara_object.text2")}
        </div>
      </div>*/}
      <div className={styles.header}>
        <div className={styles.h1}>
          <div>{t('lending.totalSupplied_text')}</div>
          <div className={styles.h2}>
            ${totalSupply?.toFixed(2)}
          </div>
        </div>
        <div className={styles.vsp}></div>
        <div className={styles.h1}>
          <div> {t('lending.totalLoaned_text')} </div>
          <div className={styles.h2}>
            ${totalLoaned?.toFixed(2)}
          </div>
        </div>
      </div>
      {inprogress && (
        <div className={styles.loadingParent}>
          <img className={styles.loading} src={loading} alt='loading spinner'/>
        </div>
      )}
      {pools.map((pool, ix) => (
        <Row
          isSmallScreen={isSmallScreen}
          pool={pool}
          key={ix}
          updatePool={updatePool}
        />
      ))}
    </div>
  );
}

function Row({ isSmallScreen, pool, updatePool }) {
  const { t } = useTranslation();
  const actions = {
    deposit: t('topup.deposit_text'),
    withdraw: t('topup.withdraw_text')
  };
  const [action, setAction] = useState(actions.deposit);
  const [expanded, setExpanded] = useState(!isSmallScreen);
  const { deposit, withdraw, approveToken, claimReward } = usePool();
  const [amount, setAmount] = useState('');
  const [disableActionBtn, setDisableActionBtn] = useState(false);
  const [disableClaimBtn, setDisableClaimBtn] = useState(false);
  const dispatch = useDispatch();
  
  function transfer() {
    if (amount?.length > 0 && !isNaN(amount)) {
      if (action === actions.withdraw) {
        setDisableActionBtn(true);
        withdraw(pool.poolAddress, amount).then((res) => {
          setDisableActionBtn(false);
          if (res.status === true)
            dispatch({
              type: 'showMessageChange',
              showMessage: true,
              txlink: res.txlink,
              messageTitle: t('transactionPopups.TransactionConfirmed_text')
            });
          updatePool(pool.poolAddress);
          setAmount('');
        });
      }
      if (action === actions.deposit) {
        setDisableActionBtn(true);
        deposit(pool.poolAddress, amount).then((res) => {
          setDisableActionBtn(false);
          if (res.status === true)
            dispatch({
              type: 'showMessageChange',
              showMessage: true,
              txlink: res.txlink,
              messageTitle: t('transactionPopups.TransactionConfirmed_text')
            });
          updatePool(pool.poolAddress);
          setAmount('');
        });
      }
    }
  }
  
  function onClaim() {
    setDisableClaimBtn(true);
    claimReward(pool.poolAddress).then((res) => {
      setDisableClaimBtn(false);
      if (res.status === true)
        dispatch({
          type: 'showMessageChange',
          showMessage: true,
          txlink: res.txlink,
          messageTitle: t('transactionPopups.TransactionConfirmed_text')
        });
      updatePool(pool.poolAddress);
    });
  }
  
  function onApprove() {
    setDisableActionBtn(true);
    approveToken(pool.poolAddress).then((res) => {
      setDisableActionBtn(false);
      if (res.status === true)
        dispatch({
          type: 'showMessageChange',
          showMessage: true,
          txlink: res.txlink,
          messageTitle: t('transactionPopups.Approved_text')
        });
      updatePool(pool.poolAddress);
    });
  }
  
  function onMax() {
    //console.log(pool);
    const v1 = (Number(pool.totalSupply) ?? 0) - (Number(pool.loan) ?? 0);
    const v2 = pool.userData?.userPoolBalanceFull ?? 0;
    if (action === actions.deposit)
      setAmount(pool.userData?.userBalanceFull?.toString() ?? '');
    else if (action === actions.withdraw)
      setAmount(Math.min(v1, v2)?.toString());
  }
  
  function onAction(action) {
    setAction(action);
    setAmount('');
  }
  
  return (
    <div className={classNames(styles.row, !expanded && styles.collapsedRow)}>
      <div className={styles.rowHeader}>
        <TokenIcon tokenName={pool.asset} className={styles.tokenIcon}/>
        <div className={styles.tokenName}>{pool.asset}</div>
        <div className={styles.apy}>{t('lending.APR_text')} {pool.apy?.toFixed(2)}%</div>
        <div className={styles.expandIconParent}>
          {expanded ? (
            <UpIcon
              className={styles.expandIcon}
              onClick={() => setExpanded(false)}
            />
          ) : (
            <DownIcon
              className={styles.expandIcon}
              onClick={() => setExpanded(true)}
            />
          )}
        </div>
      </div>
      {expanded && (
        <div className={styles.section}>
          <div className={styles.section1}>
            <div className={styles.tabTiltles}>
              <div
                onClick={() => onAction(actions.deposit)}
                className={classNames(
                  action === actions.deposit && styles.tabTiltleSelected
                )}
              >
                {t('lending.deposit_text')}
              </div>
              <div
                onClick={() => onAction(actions.withdraw)}
                className={classNames(
                  action === actions.withdraw && styles.tabTiltleSelected
                )}
              >
                {t('lending.withdraw_text')}
              </div>
            </div>
            <div
              className={classNames(
                styles.tabLine,
                action === actions.withdraw && styles.tab2Selected
              )}
            ></div>
            <div className={styles.actionTitle}>{action}</div>
            <div className={styles.inputContainer}>
              {(pool.userData?.isApproved ?? false) && (
                <button
                  disabled={disableActionBtn}
                  onClick={transfer}
                  className={styles.btn}
                >
                  {action}
                </button>
              )}
              {!(pool.userData?.isApproved ?? false) && (
                <button
                  disabled={disableActionBtn}
                  onClick={onApprove}
                  className={styles.btn}
                >
                  {t('lending.approve_text')}
                </button>
              )}
              <div onClick={onMax} className={styles.maxBtn}>
                {t('lending.max_text')}
              </div>
              <input
                value={amount}
                onChange={(evt) => {
                  const re = /^([0-9]*[.])?[0-9]+$/;
                  if (evt.target.value === '' || re.test(evt.target.value)) {
                    const val = evt.target.value
                      ? Number(evt.target.value).toString()
                      : '';
                    setAmount(val);
                  }
                }}
                type='number'
                className={styles.input}
              />
            </div>
            <div className={styles.avlblnc}></div>
          </div>
          
          <div className={styles.section2}>
            <div className={styles.row1}>
              <div className={styles.cl}>
                <div className={styles.ct}>{t('lending.balance_text')}:</div>
                <div className={styles.cVal}>
                  <div className={styles.v1}>{pool.userData?.userBalance}</div>
                  <div className={styles.v2}>{pool.asset}</div>
                </div>
              </div>
              <div className={styles.cl}>
                <div className={styles.ct}>{t('lending.supply_text')}:</div>
                <div className={styles.cVal}>
                  <div className={styles.v1}>
                    {Number(pool.totalSupply)?.toFixed(4)}
                  </div>
                  <div className={styles.v2}>{pool.asset}</div>
                </div>
              </div>
            </div>
            <div className={styles.row1}>
              <div className={styles.cl}>
                <div className={styles.ct}>{t('lending.supplied_text')}:</div>
                <div className={styles.cVal}>
                  <div className={styles.v1}>
                    {pool.userData?.userPoolBalance}
                  </div>
                  <div className={styles.v2}>{pool.asset}</div>
                </div>
              </div>
              <div className={styles.cl}>
                <div className={styles.ct}>{t('lending.loaned_text')}:</div>
                <div className={styles.cVal}>
                  <div className={styles.v1}>
                    {Number(pool.loan)?.toFixed(4)}
                  </div>
                  <div className={styles.v2}>{pool.asset}</div>
                </div>
              </div>
            </div>
          </div>
          <div className={styles.section3}>
            <div className={styles.row1}>
              <div className={styles.c3}>
                <div className={styles.c31}>{t('lending.leverage_text')}</div>
                <div className={styles.c32}>
                  {((Number(pool.totalSupply) ?? 0) - (Number(pool.loan) ?? 0))?.toFixed(4)}
                </div>
                <div className={styles.c33}>{pool.asset}</div>
                <div className={styles.c34}>
                  {pool.availableForLeverageUSD}
                </div>
                <div className={styles.c33}>$</div>
              </div>
            </div>
            
            <div className={styles.row1}>
              <div className={styles.reward}>
                <div>{t('lending.rewards_text')}:</div>
                <div className={styles.rewardVal}>
                  {pool.userData?.dividend}
                </div>
                <TokenIcon
                  tokenName={pool.asset}
                  className={styles.tokenIcon3}
                />
                <button
                  disabled={disableClaimBtn}
                  className={styles.claim}
                  onClick={onClaim}
                >
                  {t('lending.claim_text')}
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default Lending;
