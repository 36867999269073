import { useSelector } from "react-redux";

const useExecuteContract = () => {
    const userAddress = useSelector(state=>state.userAddress);
    const web3wallet = useSelector(state => state.web3);
    
    //options= { contract, functionName: string, args: array, getPromise: boolean}
    const callContract = async (options) => {
        if(!options.contract) return [{error:'no contract'}, null];
        if(!options.functionName) return [{error:'no function defined'}, null];

        const args = options.args || [];
        
        const call = options.contract.methods[options.functionName](...args).call();

        if(options.getPromise) return call;

        try{
            const result = await call;
            return [null, result];
        }
        catch(err){
            return [err, null];
        }
    }

    //data = {value, arguments, functionName}
    const sendContract  = async (contract, data) => {
        if(!contract) return [{error:'no contract'}, null];

        const txOptions = {
            from: userAddress,
            gas: 500000,
            maxPriorityFeePerGas: await web3wallet.eth.getGasPrice()
        };
        
        if(data.value > 0){
            txOptions.value = data.value;
        }
    
        let err = null; 
        const args = data.arguments ? data.arguments : [];

        const receipt = await contract.methods[data.functionName](...args)
          .send(txOptions)
          .catch((error) => {
              err = error;
              return {status:false, message:error.message, txlink:''};
          });
        
        if(err === null)
            return {status:true, message:'success', txlink: getTxLink(receipt.transactionHash)};
        else 
            return {status:false, message:err.message, txlink:''};
    }

    const getTxLink = (hash) => {
        return "https:/bscscan.com/tx/"+hash;
    }

    return { callContract, sendContract, getTxLink }
}
    
export default useExecuteContract
    