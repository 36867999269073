import React, { useEffect, useState } from 'react'
import { ReactComponent as ArrowDownIcon } from "assets/arrow-down.svg";
import styles from "./header.module.css";
import i18next from "i18next";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";

const LANGUAGE = [
  {
    code: "en",
    name: "English",
    country_code: "EN",
  },
  {
    code: "de",
    name: "German",
    country_code: "DE",
  },
  {
    code: "tr",
    name: "Turkish",
    country_code: "TR",
  },
];
const LanguageTabs = () => {
  const [languageName, setLanguageName] = useState("English");
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const currentLanguageCode = window.localStorage.getItem("i18nextLng");

  useEffect(() => {
    const currentLanguage = LANGUAGE.find((lang) => lang.code === currentLanguageCode)
    let name = currentLanguage.name

    if (name) setLanguageName(name)
  }, [currentLanguageCode])
  const toggle = () => {
    setDropdownOpen(!dropdownOpen);
  };
  const SelectLanguage = (e, item) => {
    setLanguageName(e.target.innerText);
    i18next.changeLanguage(item.code);
  };
  return (
    <Dropdown
      className={`lngHandle ${styles.buttonlang}`}
      isOpen={dropdownOpen}
      toggle={toggle}
    >
      <DropdownToggle caret>
        <ArrowDownIcon className={styles.actionBtnImg2} />
        <span className="mb-0 text-sm font-weight-bold">{languageName}</span>
      </DropdownToggle>
      <DropdownMenu className={styles.drop_open}>
        {LANGUAGE?.map((item) => {
          return (
            <DropdownItem
              key={item.code}
              className={styles.drop_item}
              onClick={(e) => {
                SelectLanguage(e, item);
              }}
            >
              {item.name}
            </DropdownItem>
          );
        })}
      </DropdownMenu>
    </Dropdown>
  );
};

export default LanguageTabs;
